<script>
import { mapState } from 'vuex';

export default defineNuxtComponent({
  name: 'PublicLayout',
  head() {
    return {
      titleTemplate: `%s - ${this.title || 'Workstate'}`,
    };
  },
  computed: {
    ...mapState('ContextStore', ['title']),
  },
});
</script>

<template>
  <div id="app">
    <Nuxt />
  </div>
</template>
